.footer {
    background-color: #1B1B1B;
    color: #bababa;
    padding: 100px 0;
    @include _768 {
        padding: 50px 0;
    }

    &__top {
        border-bottom: 2px solid #545454;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 35px;
        margin-bottom: 32px;
    }

    &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 10px;
    }
}

.footer-nav {
    color: #fff;
    display: flex;
    gap: 10px;
    @include _768 {
        flex-direction: column;
    }

    a {
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
    }
}

.footer-sub {

    a {
        position: relative;

        &:not(:last-child)::after {
            content: '|';
            margin: 0 8px;
        }
    }
}