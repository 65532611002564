.products {
    margin-bottom: 75px;
    @include _768 {
        margin-bottom: 60px;
    }

    &__inner {
        display: flex;
        gap: 10px;
        @include _768 {
            overflow-x: auto;
        }
    }
}

.products-item {
    flex: 1;
    @include _768 {
        width: 60vw;
        flex: none;
    }

    &:hover {
        .products-item__img {
            transform: translateY(-10px);
        }
        .products-item__content {
            background-color: #e7112d;
        }
    }

    &__content {
        background-color: #414141;
        color: #EBEBEB;
        padding: 90px 29px 35px;
        height: 225px;
        transition: .2s;
        @include _1160 {
            height: 245px;
        }
        @include _768 {
            height: 230px;
        }
    }

    &__img {
        margin-bottom: -80px;
        text-align: center;
        transform: none;
        transition: .2s;
        will-change: transform;
    }

    &__title {
        font-family: "AlongSans";
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 7px;
        text-transform: uppercase;
    }

    &__text {
        color: #dbdbdb;
        font-size: 11px;
        line-height: 1.4;
        text-transform: capitalize;
    }
}