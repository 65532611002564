.partners {
    margin-bottom: 130px;
    @include _768 {
        margin-bottom: 60px;
    }

    img {
        filter: grayscale(1);
        transition: filter .2s;

        &:hover {
            filter: none;
        }
    }
}

.partners-head {
    text-align: center;

    &__sub {
        color: #979797;
        margin-top: 10px;
        margin-bottom: 65px;
    }
}

.partners-top {
    display: flex;
    flex-wrap: wrap;
    column-gap: 18px;
    row-gap: 30px;
    padding-bottom: 30px;
    border-bottom: 2px solid #D9D9D9;
    margin-bottom: 40px;
    @include _768 {
        justify-content: space-between;
        column-gap: 10px;
        row-gap: 20px;
    }

    img {
        max-width: 136px;
        @include _768 {
            width: 100px;
        }
    }
}

.partners-bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
    @include _768 {
        gap: 20px;
    }

    img {
        max-width: 80px;
    }
}