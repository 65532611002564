.statics {
    margin-bottom: 80px;
    @include _768 {
        margin-bottom: 60px;
        overflow: hidden;
    }
}

.statics-top {
    border-bottom: 2px solid #D9D9D9;
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    margin-bottom: 55px;
    @include _768 {
        flex-direction: column;
        gap: 10px;
        margin-bottom: 30px;
    }
}

.statics-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 70px;
    @include _768 {
        flex-direction: column;
        margin-bottom: 45px;
        align-items: flex-start;
        gap: 15px;
    }
}

.static-num {

    span {
        color: #636363;
        font-size: 24px;
        @include _768 {
            font-size: 20px;
        }
    }

    .head {
        color: #343434;
        font-size: 70px;
        font-weight: 600;
        @include _768 {
            font-size: 60px;
        }
    }
}

.static-num-text {
    color: #636363;
    max-width: 330px;
    font-size: 14px;
}

.statics-list {
    --size: 187px;
    display: flex;
    @include _1160 {
        justify-content: center;
    }
    @include _768 {
        margin: 0 -20px;
        justify-content: flex-start;
        overflow-x: auto;
    }

    &__item {
        border-radius: 50%;
        border: 1px solid #E7112D;
        color: #E7112D;
        height: var(--size);
        width: var(--size);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        padding: 20px;
        flex: none;
        text-align: center;
        // transform: none;
        // transition: .2s;
        will-change: transform;
        @include _1160 {
            --size: 160px;
        }
        @include _768 {
            --size: 140px;
            font-size: 20px;
        }

        // &:hover {
        //     transform: scale(1.1);
        // }

        &:not(:first-child) {
            margin-left: -12px;
        }

        &.active {
            background-color: #E7112D;
            color: #fff;
        }
    }
}