*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-size: 16px;
}

html,
body {
    min-height: 100%;
    position: relative;

    line-height: normal;
    font-family: "VelaSans", sans-serif;
}

body {
    background-color: #fff;
    color: #252525;
    font-size: 1rem;
}

.main {
    max-width: 1920px;
    margin: 0 auto;
    overflow: hidden;
}

ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "AlongSans", sans-serif;
    font-weight: 800;
}

h2 {
    color: #1F1F1F;
    font-size: 38px;
    font-weight: 800;
    text-transform: uppercase;
    @include _768 {
        font-size: 30px;
    }

    .light {
        color: #E7112D;
    }
}

.head {
    font-family: "AlongSans", sans-serif;
}

.head-think {
    font-family: "AlongSans", sans-serif;
    font-weight: 400;
}

.sub-head {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;

    &--products {
        margin-top: 5px;
        margin-bottom: 40px;
        @include _768 {
            margin-bottom: 15px;
        }
    }

    &--service {
        color: #cccccc;
        position: relative;
        margin-top: 4px;
        margin-bottom: 55px;
        padding-bottom: 25px;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            border-bottom: 3px solid #E7112D;
            width: 135px;
        }
    }
}

a {
    color: inherit;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

img {
    display: block;
    max-width: 100%;
    margin: 0 auto;
}

.logo {
    margin-left: 0;
    width: 140px;
}

.container {
    max-width: 1100px;
    padding: 0 20px;
    margin: 0 auto;
    position: relative;
}

.content {
    padding: 50px 20px;

    h1 {
        margin-bottom: 30px;
    }

    ol {
        padding-left: 16px;

        ol {
            padding-left: 0;
        }

        li {
            position: relative;
            margin: 15px 0;
        }
    }

    & > ol > li {
        counter-increment: inst;

        li::before {
            content: counter(inst) ".";
            position: absolute;
            left: -32px;
        }

        & > ol {
            padding-left: 16px;
        }
    }

    & > ol > li > ol > li {
        counter-increment: str;

        li::before {
            left: -48px;
        }

        li::after {
            content: counter(str) ".";
            position: absolute;
            top: 0;
            left: -32px;
        }

        & > ol {
            padding-left: 16px;
        }
    }

    p {
        margin-bottom: 20px;
    }
}