.about {
    margin-bottom: 75px;
    @include _768 {
        margin-bottom: 60px;
    }

    &__inner {
        text-align: center;

        .about-head {
            color: #b1b1b1;
            font-size: 22px;
            margin-bottom: 20px;
        }

        h2 {
            margin-bottom: 70px;
            @include _768 {
                margin-bottom: 45px;
                br {
                    display: none;
                }
            }
        }
    }
}

.about-bg {
    height: 570px;
    @include _1160 {
        height: 500px;
    }
    @include _768 {
        height: 350px;
    }

    img {
        height: 100%;
        object-fit: cover;
    }
}