.hero {
    color: #fff;
    height: 745px;
    margin-bottom: 84px;
    position: relative;
    padding-top: 140px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    @include _768 {
        padding-top: 35px;
        height: 80vh;
        margin-bottom: 60px;
    }

    &__inner {
        height: 100%;
        width: 100%;
        padding-top: 90px;
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        @include _768 {
            padding-top: 10vh;
            pointer-events: none;
        }
    }

    &__top,
    &__bottom {
        display: flex;
        justify-content: space-between;
    }
}

.header {
    position: relative;
    z-index: 1;
    @include _768 {
        pointer-events: none;
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .header-controls {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        line-height: 1;

        .swiper-pagination {
            position: relative;
            top: 0;
        }
    }
}

.hero-slider {
    background-color: #172845;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;

    img {
        height: 100%;
        object-fit: cover;
    }
}

.hero-head {

    h2 {
        color: #fff;
        margin-bottom: 8px;
    }

    .head-think,
    .sub-head {
        opacity: 0;
        transform: translateY(10px);
    }

    .head-think.show,
    .sub-head.show {
        transform: none;
        opacity: 1;
        transition: opacity .5s .2s, transform .5s .2s;
    }
    .sub-head.show {
        transition-delay: .5s;
    }
}

.hero-list {
    display: flex;
    flex-direction: column;
    gap: 18px;
    padding-left: 40px;
    @include _768 {
        display: none;
    }

    a {
        position: relative;
        font-size: 14px;
        text-transform: uppercase;
        text-decoration: none;

        &::before {
            content: '';
            position: absolute;
            left: -40px;
            top: 8px;
            width: 0;
            border-bottom: 2px solid #E7112D;
            transition: width .2s;
        }

        &.active {
            font-weight: 500;

            &::before {
                width: 25px;
            }
        }
    }
}

.hero-actions {
    display: flex;
    gap: 2px;
    overflow: hidden;
    margin-right: -195px;
    @include _768 {
        flex: 1;
        margin: 0 -20px;
        pointer-events: all;
    }
}

.btn-hero {
    appearance: none;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    color: #fff;
    background-color: transparent;
    font-family: "AlongSans", sans-serif;
    font-size: 22px;
    height: 135px;
    padding: 30px 40px;
    display: flex;
    align-items: flex-end;
    text-transform: uppercase;
    transition: .2s;
    @include _768 {
        padding: 20px;
        height: 80px;
        flex: 1;
        justify-content: center;
        font-size: 20px;
    }

    &--contact {
        background-color: #232323;
        width: 270px;
        @include _768 {
            width: 50%;
        }

        &:hover {
            background-color: #0e0e0e;
        }
    }

    &--catalog {
        background-color: #E7112D;
        width: 480px;
        @include _768 {
            width: 50%;
        }

        &:hover {
            background-color: #d70521;
        }

        svg {
            margin-left: 10px;
            margin-bottom: 2px;
        }
    }
}

.hero-scroll {
    border-top: 3px solid #E7112D;
    padding-top: 10px;
    @include _768 {
        display: none;
    }
}

.btn-scroll {
    appearance: none;
    border: none;
    background-color: transparent;
    color: #CEDCE6;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 0;

    span {
        display: inline-block;
        margin-right: 15px;
    }
}