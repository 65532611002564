.service {
    height: 505px;
    position: relative;
    margin-bottom: 90px;
    @include _1160 {
        height: 465px;
    }
    @include _768 {
        margin-bottom: 60px;
        height: auto;
    }

    .container {
        height: 100%;
    }

    &__inner {
        color: #fff;
        position: relative;
        height: 100%;
        padding: 80px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include _768 {
            padding: 50px 0;
        }

        h2 {
            color: #fff;
        }
    }
}

.service-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    img {
        height: 100%;
        object-fit: cover;
        @include _768 {
            object-position: 40%;
        }
    }
}

.service-list {

    .head {
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
    }

    ul {
        padding-left: 20px;
    }

    li {
        color: #ccc;
        line-height: 1.6;
    }
}

.service-block {
    color: #fff;
    border-radius: 2px;
    background-color: #E7112D;
    padding: 48px 45px 50px 70px;
    max-width: 630px;
    position: absolute;
    right: 0;
    bottom: -8%;
    font-size: 14px;
    @include _768 {
        position: relative;
        margin: 0 -20px;
        padding: 40px;
    }
}

.quote {
    position: absolute;

    &--left {
        top: 20px;
        left: 20px;
        @include _768 {
            top: 10px;
            left: 10px;
        }
    }

    &--right {
        bottom: 20px;
        right: 20px;
        @include _768 {
            bottom: 10px;
            right: 10px;
        }
    }
}