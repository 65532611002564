.contacts {
    padding: 60px 0 0;
    overflow: hidden;

    &__inner {
        display: flex;
        justify-content: space-between;
        position: relative;
        @include _768 {
            flex-direction: column;
        }
    }
}

.contacts-content {
    background: url(../img/contact-logo.png) 101% center #E7112D no-repeat;
    border-radius: 2px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 20px;
    padding: 40px 50px;
    margin: -60px 80px -140px 0;
    height: 355px;
    width: 395px;
    @include _768 {
        margin: 25px -20px 0;
        height: auto;
        width: calc(100% + 40px);
        padding: 30px 20px;
        background-position: 101%;
    }

    &__title {
        font-size: 28px;
        font-weight: 400;
        @include _768 {
            font-size: 24px;
        }
    }

    &__bottom {
        margin-top: auto;
        font-size: 22px;
        @include _768 {
            margin-top: 35px;
            font-size: 18px;
        }
    }

    p {
        margin-bottom: 5px;
        &:last-child {
            margin-bottom: 0;
        }
    }

    .pink {
        color: #f8b7c0;
    }
}

.contacts-bg {
    height: 450px;
    @include _768 {
        height: 260px;
    }

    img {
        height: 100%;
        object-fit: cover;
    }
}